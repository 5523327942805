import styled from 'styled-components';

export const Container = styled.main`
    width: 100%;
    height: 100%;
    .conatinerInicial {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-bottom: 5px solid #3e4095;
        width: 100%;
        height: 450px;
        .containerWallpaper {
            position: absolute;
            width: 100%;
            height: 450px;
            .wallpaper {
                filter: brightness(60%);
            }
        }
        .wallpaper {
            width: 100%;
            height: 100%;
        }
        .containerText {
            display: flex;
            flex-direction: column;
            z-index: 100;
            color: #c5c7ff;
            .titlle {
                font-size: 3.0rem;
                max-width: 800px;
                margin-bottom: 40px;
            }
            .text {
                font-size: 1.5rem;
            }
        }
    }
    
    @media screen and (max-width: 780px){
        .containerText {
            padding: 40px;
            .titlle {
                font-size: 2.5rem !important;
                text-align: center;
            }
            .text {
                font-size: 1.2rem !important;
                text-align: center;
            }
        }
    }

    @media screen and (max-width: 400px){
        .conatinerInicial {
            justify-content: center !important;
            .containerText {
                padding: 20px;
                .titlle {
                    font-size: 2rem !important;
                }
                .text {
                    font-size: 1.2rem !important;
                }
            }
        }
    }
`;