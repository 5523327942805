import styled from 'styled-components';

export const ContainerProdutos = styled.div`
    width: 100%;
    padding: 20px;
    background: rgb(40, 40, 40);
    transition: .5s;
    .tituloProdutos {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
            margin: 10px 0px;
            color: #d4d4d4;
            width: 90%;
            text-align: center;
        }
        label {
            width: 90%;
            height: 2px;
            background: #ecd554;
        }
        p {
            width: 65%;
            margin-top: 20px;
            font-size:  1.4rem;
            line-height: 2rem;
            text-indent: 1.8rem;
            text-align: justify;
            color: #d4d4d4;
        }
    }
    @media (max-width: 700px) {
       p {
           font-size: 1.2rem !important;
           width: 100% !important;
       }
    }
`;

export const ContentCategoriesImgRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    .text {
        width: 50%;
        color: #d4d4d4;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h1 {
            margin-bottom: 40px;
            width: 100%;
            padding: 20px;
            text-align: center;
            border-bottom: 2px solid #3e4095;
        }
        p {
            font-size: 1.2rem;
            line-height: 2.5rem;
            text-align: justify;
            width: 90%;
        }

    }
    .imageImagens {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        margin: 100px 0;
        img {
            padding: 5px;
            border-radius: 20px;
            width: 200px !important;
            height: 200px !important;
        }
    }
    @media (max-width: 1100px) {
        .imageImagens {
            flex-wrap: wrap;
            margin: 40px 0;
            img {
                width: 200px;
                height: 200px;
            }
        }
    }
    @media (max-width: 850px) {
        flex-direction: column;
        width: 100%;
        .imageImagens {
            flex-wrap: wrap;
            margin-top: 40px;
        }
        .text {
            margin: 0;
            margin-top: 25px;
            width: 100% ;
        }
    }
    @media (max-width: 600px) {
        .imageImagens {
            img {
                width: 180px !important;
                height: 180px !important;
            }
        }
    }
    @media (max-width: 400px) {
        .imageImagens {
            img {
                width: 140px !important;
                height: 140px !important;
            }
        }
    }
`;

export const ContentCategoriesImgLeft = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    .text {
        width: 50%;
        color: #d4d4d4;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h1 {
            margin-bottom: 40px;
            width: 90%;
            padding: 20px;
            text-align: center;
            border-bottom: 2px solid #3e4095;
        }
        p {
            font-size: 1.2rem;
            line-height: 2.5rem;
            text-align: justify;
            width: 90%;
        }

    }
    .imageImagens {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        img {
            padding: 5px;
            border-radius: 20px;
            width: 200px !important;
            height: 200px !important;
        }
    }
    @media (max-width: 1100px) {
        .imageImagens {
            flex-wrap: wrap;
            margin: 40px 0;
            img {
                width: 200px;
                height: 200px;
            }
        }
    }
    @media (max-width: 850px) {
        flex-direction: column-reverse;
        width: 100%;
        .imageImagens {
            flex-wrap: wrap;
            margin-top: 40px;
        }
        .text {
            margin: 0;
            width: 100% ;
        }
    }
    @media (max-width: 600px) {
        .imageImagens {
            img {
                width: 180px !important;
                height: 180px !important;
            }
        }
    }
    @media (max-width: 400px) {
        .imageImagens {
            img {
                width: 140px !important;
                height: 140px !important;
            }
        }
    }
`;

export const ContainerOtherProducts = styled.section`
    width: 100%;
    color: #d4d4d4;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1 {
        margin-bottom: 40px;
        font-size: 28px;
        width: 50%;
        text-align: center;
        padding: 20px;
        border-bottom: 2px solid #ecd554;
    }
    p {
        width: 65%;
        margin-top: 20px;
        font-size:  1.4rem;
        line-height: 2.5rem;
        text-indent: 1.8rem;
        text-align: justify;
        color: #d4d4d4;
    }
    .showImages {
        max-width: 70%;
        margin: 40px 0;
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .boxImages {
            margin: 10px 10px;
            img {
                width: 200px;
                height: 200px;
                border-radius: 10px
            }
        }
    }
    @media (max-width: 850px) {
        .maisProdutos { width: 100%; }
        .showImages {
            max-width: 100% !important;
            .boxImages {
                img {
                    width: 150px !important;
                    height: 150px !important;
                }
            }
        }
    }

    @media (max-width: 400px) {
        .showImages {
            .boxImages {
                img {
                    width: 120px !important;
                    height: 120px !important;
                }
            }
        }
    }
`;