import styled from 'styled-components';

export const ContainerHeader = styled.header`
    width: 100vw;
    height: 75px;
    background: ${props => props.color};
    border-top: 2px solid #ecd554;
    z-index: 1;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: .5s;
    overflow: auto;
    .img {
        width: 250px;
        margin-left: 50px;
        img {
            width: 250px;
        }
    }
    .menu {
        width: 100%;
        ul {
            list-style: none;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right:50px;
            li {
                color: #f5f5f5;
                height: 75px;
                padding: 20px 20px;
                font-size: 1.08rem;
                cursor: pointer;
                align-self: center;
                display: flex;
                align-items: center;
                transition: .2s;
                &:hover {
                    color: #ecd554;
                }
            }
        }
    }
    @media screen and (max-width: 780px){
        .menu {display: none}
        .img {
            margin-left: 0;
            width: 200px;
            img {
                width: 200px;
            }
        }
        justify-content: center;
        
    }
`;