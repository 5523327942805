import React from 'react';
import { ContentLocation } from './styles';

export default function Location() {
    return(
        <ContentLocation>
            <h1 className="tittleLocation">Venha nos fazer uma visita</h1>
            <p>
                Para que você possa conferir todo o nosso potencial, que tal nos fazer uma visita
                assim você pode comprovar nossa qualidade e ótimo atendimento visando o bem estar do cliente 
            </p>
            <iframe title="localização JS" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3508.680534341755!2d-48.88334108529!3d-28.428894982498548!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x952145a871b3bd1f%3A0x5624eb6902afecd3!2sJS%20Parafusos%20e%20Ferramentas!5e0!3m2!1spt-BR!2sbr!4v1573417884600!5m2!1spt-BR!2sbr" frameBorder="0"></iframe>
        </ContentLocation>
    );
}
