import styled from 'styled-components';

export const ContentFooter = styled.footer`
    height: 200px;
    padding: 0 150px;
    width: 100%;
    background-color: rgb(40,40,40);
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #d4d4d4;
    .containerAddress { 
        margin-top: 40px;
        h4{ 
            margin-bottom: 10px;
            border-bottom: 0.15rem solid #ecd554;
            width: 150px;
            height: 22px;
        }
        p {
            line-height: 25px;
        }
    }
    .containerSocial {
        margin-top: 40px;
        h4{ 
            margin-bottom: 10px;
            border-bottom: 0.15rem solid #ecd554;
            width: 200px;
            height: 22px;
        }
        .linksSocial {
            display: flex;
            align-items: center;
            margin: 10px 0;
            a {
                display: flex;
                align-items: center;
                text-decoration: none;
                &:hover {
                    p{ color: white }
                    svg { filter: brightness(150%) }
                }
                svg { 
                    transition: color .4s;
                    filter: brightness(80%)
                }
                p {
                    transition: color .4s;
                    color: #d4d4d4;
                    margin-left: 10px;
                }
            }
        }
    }
    @media (max-width: 850px) {
        padding: 0 100px;
    }

    @media (max-width: 700px) {
        flex-direction: column;
        padding: 0 60px;
        height: 100%;
        .containerAddress {
            width: 73%;
        }
        .containerSocial {
            margin-top: 25px !important;
            margin-bottom: 25px !important;
            width: 73%;
        }
        .linksSocial {
            margin: 25px 0!important;   
        }
    }

    @media (max-width: 450px) {
        padding: 0 !important;
    }
`;