import React from 'react';
import { ContentMain } from './styles';
import { MdKeyboardArrowRight } from 'react-icons/md';

import planejamento from '../../assets/images/placa.jpg';
import missao from '../../assets/images/frente.jpg';
import valores from  '../../assets/images/valores.jpg';

export default function Sobre() {
    return(
        <ContentMain id="sobre">
            <div className="titulo">
                <h1>Sobre nós</h1>
                <label></label>
            </div>
            <div className="contentSobre animation1">
                <div className="text">
                    <h4>Quem somos</h4>
                    <p className="conteudo"> A JS Parafusos e Ferramentas é uma empresa catarinense,
                        situada na cidade de Pescaria Brava. Fundada em 2011
                        pelo jovem empreendedor Jaylor Vieira Martins. Ele está
                        sempre elaborando  melhorias e novas técnicas para aperfeiçoar o
                        seu negócio estabilizando  uma base sólida e confiável, tanto
                        para seus clientes quanto para seus colaboradores. A principal atividade
                        é a comercialização de materiais elétricos, tubos e conexões, ferramentas e
                        parafusos e muito mais que você pode conferir na loja.
                    </p>
                </div>
                <div className="imagemDireita">
                    <img src={planejamento} alt="imagem de quem somos"/>
                </div>
            </div>
            <div className="contentSobre animation2">
                <div className="imagemEsquerda">
                    <img src={missao} alt="imagem de missao js"/>
                </div>
                <div className="text">
                    <h4>Nossa missão</h4>
                    <p className="conteudo"> Temos como principal objetivo a criação e a solidificação de ótimas
                        parcerias, tanto com nossos clientes  como com nossos fornecedores,
                        prezando sempre pela satisfação dos mesmos. Trazendo produtos de procedência,
                        confiabilidade e qualidade. Queremos que em nossa trajetória,  pudéssemos
                        nos tornar adaptativos ao mercado onde estamos atuando, trazendo sempre produtos
                        que nossos clientes procuram. E através de nosso trabalho nos tornar cada
                        vez mais sólidos e competitivos, buscando inovação e aperfeiçoamento.
                    </p>
                </div>
            </div>
            <div className="contentSobre animation3">
                <div className="text">
                    <h4>Valores</h4>
                    <p className="subtitulo"><MdKeyboardArrowRight size={22}/>Confiança e credibilidade:</p>
                    <p className="contValores">como mencionado queremos passar credibilidade,
                        para que nossos clientes e fornecedores possam sentir-se confortáveis com nossos serviços.
                    </p>
                    <p className="subtitulo"><MdKeyboardArrowRight size={22}/>Ética e responsabilidade:</p>
                    <p className="contValores">sim nós temos um compromisso: a responsabilidade de entregar a você
                        que busca os melhores produtos para seu lar, e com preços justos.
                    </p>
                    <p className="subtitulo"><MdKeyboardArrowRight size={22}/>Parceria:</p>
                    <p className="contValores">Nossos clientes são nossos parceiros e como tal estaremos sempre dispostos ajudar
                        no que for necessário para satisfazê-los.
                    </p>
                </div>
                <div className="imagemDireita">
                    <img src={valores} alt="imagem de quem somos"/>
                </div>
            </div>
        </ContentMain>
    );
}