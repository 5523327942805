import styled from 'styled-components';

export const ContentMain = styled.main`
    margin: 40px 0;
    padding: 0 20px;
    opacity: ${props => props.show};
    position: relative;
    background: transparent;
    top: ${props => props.animation};
    transition: .5s;
    .titulo {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
            margin: 10px 0;
        }
        label {
            width: 50%;
            height: 2px;
            background: #3e4095;
        }
    }
    .contentSobre {
        width: 100%;
        margin-top: 40px;
        background: #d4d4d4;
        padding: 40px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        .text {
            width: 50%;
            h4 {
                margin-bottom: 10px;
                font-size: 1.8rem;
            }
            p.conteudo {
                text-indent: 1.5rem;
                line-height: 1.8rem;
                font-size: 1.5rem;
                text-align: justify;
            }
            p.subtitulo {
                font-weight: bold;
                font-size: 1.2rem;
                display: flex;
                align-items: center;
                font-style: italic;
            }
            p.contValores {
                margin-top: 5px;
                margin-bottom: 10px;
                margin-left: 20px;
                font-size: 1.2rem;
            }
        }
        .imagemDireita {
            width: 50%;
            text-align: right;
            img {
                border-radius: 5px;
                border: 2px solid #3e4095;
                width: 80%;
            }
        }
        .imagemEsquerda {
            width: 50%;
            margin-right: 20px;
            img {
                border-radius: 5px;
                border: 2px solid #3e4095;
                width: 80%;
            }
        }
    }
    .animation1 {transition: .5s}
    .animation2 {transition: 1s}
    .animation3 {transition: 1.5s}
    @media screen and (max-width: 780px){
        .titulo label {
            width: 80%;
        }
        .contentSobre {
            padding: 20px !important;
            .text {
                h4 {
                    margin-bottom: 10px;
                    font-size: 1.6rem;
                }
                p.conteudo {
                    font-size: 1.2rem;
                    margin-bottom: 20px;
                }
            }
            flex-direction: column;
            .imagemEsquerda {
                width: 100% !important;
                margin-bottom: 20px;
                text-align: center;
                margin-right: 0;
            }
            .imagemDireita {
                width: 100% !important;
                text-align: center;
                margin-top: 20px;
                margin-left: 0 !important;
            }
            .text {
                width: 100% !important;
            }
        }
    }
`;