import styled from 'styled-components';

export const ContentBackToTop = styled.button`
    opacity: ${props => props.opacity};
    position: fixed;
    z-index: 100;
    bottom: ${props => props.bottom};
    color: rgb(40, 40, 40);
    right: 50px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #ecd554;
    border: none;
    outline: none;
    cursor: pointer;
    transition: .5s;
    &:hover {
        color: black;
        background: #d6bb22;
    }
    @media (max-width: 850px) {
        right: 20px;
        width: 50px;
        height: 50px;
    }
`;