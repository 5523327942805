import styled from 'styled-components';

export const ContentLocation = styled.div`
    width: 100%;
    margin: 40px 0px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
        margin-bottom: 20px;
        margin-left: 20px;
        width: 45%;
        text-align: center;
        font-size: 22px;
        height: 35px;
        border-bottom: 2px solid #3e4095; 
    }
    p {
        margin-bottom: 40px;
        width: 50%;
        font-size: 18px;
        line-height: 2rem;
        text-align: justify;
    }
    iframe {
        width: 90%;
        height: 350px;
        border-radius: 10px;
    }
    @media (max-width: 850px) {
        h1 {
            width: 90%;
            margin-left: 0;
        }
        p {
            width: 100%;
            line-height: 1.8rem;
        }
        .tittleLocation {width: 100%;}
    }
`;