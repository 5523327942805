import React, { useState, useEffect } from 'react';
import { Container } from './styles';

import Header from '../../components/Header';
import Sobre from '../../components/Sobre';
import Produtos from '../../components/Produtos';
import Footer from '../../components/Footer';
import BackToTop from '../../components/Backtotop';
// import Newsletter from '../../components/Newsletter';
import Location from '../../components/Location';
import wallpaper from '../../assets/images/wallpaper.jpg';

export default function Home() {
    const [scroll, setScroll] = useState(0);
    const [opacity] = useState(0);

    function handleScroll() {
        const position = window.pageYOffset;
        setScroll(position);
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    });

    return(
        <Container opacity={opacity}>
            <BackToTop scroll={scroll}/>
            <Header scroll={scroll}/>
            <div className="conatinerInicial">
                <div className="containerWallpaper">
                    <img className="wallpaper" src={wallpaper} alt="wallpaper js"/>
                </div>
                <div className="containerText">
                    <h1 className="titlle">JS, evoluindo nossos potenciais, inovando nossos serviços</h1>
                    <p className="text">Toda confiança que você precisa para comprar com segurança</p>
                </div>
            </div>
            <Sobre scroll={scroll}/>
            <Produtos scroll={scroll}/>
            <Location />
            {/* <Newsletter /> */}
            <Footer />
        </Container>
    );
}