import React from 'react';
import { FaWhatsapp, FaInstagram } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';
import { ContentFooter } from './styles';

export default function Footer() {
    return(
        <ContentFooter>
            <div className="containerAddress">
                <h4>Endereço</h4>
                <p>Rua Leandro Thomaz Francisco <br/> s/n, KM 37. Pescaria Brava/SC</p>
            </div>
            <div className="containerSocial">
                <h4>Conecte-se Conosco</h4>
                <div className="linksSocial">
                    <a href="https://api.whatsapp.com/send?phone=554831920252&text=Ol%C3%A1" target="_blank" rel="noopener noreferrer">
                        <FaWhatsapp size={24} color="#00BB2D"/>
                        <p> +55 (48) 3192-0252</p>
                    </a>
                </div>
                <div className="linksSocial">
                    <a href="mailto:faleconosco@js.ind.br?subject=Olá JS" target="_blank" rel="noopener noreferrer">
                        <FiMail size={24} color="#ecd554"/>
                        <p>faleconosco@js.ind.br</p>
                    </a>
                </div>
                {/* <div className="linksSocial">
                    <a href="https://www.instagram.com/bruno_zanella8/" rel="noopener noreferrer">
                        <FaInstagram size={24} color="#c4302b"/>
                        <p></p>
                    </a>
                </div> */}
            </div>
        </ContentFooter>
    );
}