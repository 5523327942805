import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box
  }

  body {
    font-family: 'Helvética', sans-serif;
    background: #f5f5f5;
  }

  :root {
    font-size: 14px;
    color: #494949;
    cursor: default;
  }
`;

export default GlobalStyle;
