import React, { useState, useEffect } from 'react';
import { ContainerHeader } from './styles';

import logo from '../../assets/logo/jsLogo.png';

export default function Header(props) {
    const [colorHeader, setColorHeader] = useState('rgba(0, 0, 0, .1)');

    function handleScroll(whereScroll) {
        const sobre = document.querySelector("#sobre");
        const produtos = document.querySelector("#produtos");
        
        if(whereScroll === 'about') {
            window.scrollTo({
                top: sobre.offsetTop - 400,
                behavior: 'smooth'
            });
        }
        if(whereScroll === 'product') {
            window.scrollTo({
                top: produtos.offsetTop - 200,
                behavior: 'smooth'
            });
        }
        if(whereScroll === 'contact') {
            window.scrollTo({
                top: 100000000,
                behavior: 'smooth'
            });
        }
    }

    useEffect(() => {
        props.scroll >= 250 ? setColorHeader('rgb(40, 40, 40)') : setColorHeader('rgba(0, 0, 0, .1)');
    }, [props.scroll]);

    return(
        <ContainerHeader color={colorHeader}>
            <div className="img">
                <img src={logo} alt="logo js parafusos e ferramentas"/>
            </div>
            <div className="content">
                <nav className="menu">
                    <ul>
                        <li onClick={() => {handleScroll('about')}}>Sobre</li>
                        <li onClick={() => {handleScroll('product')}}>Nosso serviços</li>
                        <li onClick={() => {handleScroll('contact')}}>Contato</li>
                    </ul>
                </nav>
            </div>
        </ContainerHeader>
    );
}