import React from 'react';
import { ContainerProdutos, ContentCategoriesImgRight, ContentCategoriesImgLeft, ContainerOtherProducts } from './styles';

//Tubos e conexões
import caixas from '../../assets/images/caixas.jpg';
import derivacao from '../../assets/images/derivacao.jpg';
import reducao from '../../assets/images/reducao.jpg';
import registro from '../../assets/images/registro.jpg';

//Material Eletrico.
import tomada from '../../assets/images/tomada.jpg';
import caixa from '../../assets/images/caixaDis.jpg';
import disjuntor from '../../assets/images/disjuntor.jpg';
import interruptor from '../../assets/images/interruptor.jpg';

//ferragens e ferramentas
import cadeado from '../../assets/images/cadeado.jpg';
import chave from '../../assets/images/chave.jpg';
import tranca from '../../assets/images/tranca.jpg';
import dobradica from '../../assets/images/dobradica.jpg';

//Images Outros Produtos
import bomba from '../../assets/images/bomba.jpg';
import bracadeira from '../../assets/images/bracadeira.jpg';
import bucha from '../../assets/images/bucha.jpg';
import chuveiro from '../../assets/images/chuveiro.jpg';
import lampada from '../../assets/images/lampada.jpg';
import mangueira from '../../assets/images/mangueira.jpg';
import parafusos from '../../assets/images/parafusos.jpg';
import pincel from '../../assets/images/pincel.jpg';
import torneira from '../../assets/images/torneira.jpg';
import trena from '../../assets/images/trena.jpg';

export default function Produtos(props) {

    const imagesOther = [bomba, bracadeira, bucha, chuveiro, lampada, mangueira, parafusos, pincel, torneira, trena];  

    return(
        <ContainerProdutos id="produtos">
            <div className="tituloProdutos">
                <h1>Nossos produtos</h1>
                <label></label>
                <p>
                    Nós através de nossa dedicação e muito trabalho, estamos no mercado para entregar
                    o melhor posível em termos de qualidade nos produtos que levamos até você,
                    pois temos um compromisso com cada um de nossos clientes, e esse compromisso, é o nosso combustível
                    para continuar sempre inovando os serviços prestados.  
                </p>
            </div>
            <ContentCategoriesImgRight>
                <div className="text">
                    <h1>Tubos e conexões</h1>
                    <p>
                        Conte com a JS quando precisar de produtos para a hidraulica de sua casa,
                        contamos com tubos de variados tamanhos, registros, derivações, reduções e claro, varios outros produtos
                        que você pode conferir em nossa loja, venha nos visitar.
                    </p>
                </div>
                <div className="imageImagens">
                    <img src={derivacao} width="200" alt="imagen tubos econexões"/>
                    <img src={reducao} width="200" alt="imagen tubos econexões"/>
                    <img src={registro} width="200" alt="imagen tubos econexões"/>
                    <img src={caixas} width="200" alt="imagen tubos econexões"/>
                </div>
            </ContentCategoriesImgRight>
            <ContentCategoriesImgLeft>
                <div className="imageImagens">
                    <img src={tomada} width="200" alt="imagen materiais eletricos"/>
                    <img src={caixa} width="200" alt="imagen materiais eletricos"/>
                    <img src={disjuntor} width="200" alt="imagen materiais eletricos"/>
                    <img src={interruptor} width="200" alt="imagen dmateriais eletricos"/>
                </div>
                <div className="text">
                    <h1>Materiais elétricos</h1>
                    <p>
                        Segurança e confiança devem ser prioridade quando se fala no sistema elétrico de sua residência,
                        por esse motivo oferecemos a você tomadas, disjuntores, interruptores, lâmpadas, e como você já sabe, 
                        tem mais em nossa loja sempre oferecendo auquele preço amigo e qualidade total nos produtos entregues.
                    </p>
                </div>
            </ContentCategoriesImgLeft>
            <ContentCategoriesImgRight>
                <div className="text">
                    <h1 className="FerragensText">Ferragens e ferramentas</h1>
                    <p>
                        Possuimos grande variedade de produtos na linha de ferragens e ferramentas, soluções como trancas, dobradiças,
                        chaves de testes, chaves philips, e de boca, certamente oque você precisa estará em nossa loja, com nossa garantia
                        de entregar a você sempre o melhor que podemos oferecer. 
                    </p>
                </div>
                <div className="imageImagens">
                    <img src={cadeado} width="200" alt="imagen ferragens"/>
                    <img src={chave} width="200" alt="imagen ferragens"/>
                    <img src={tranca} width="200" alt="imagen ferragens"/>
                    <img src={dobradica} width="200" alt="imagen ferragens"/>
                </div>
            </ContentCategoriesImgRight>    
            <ContainerOtherProducts>
                <h1 className="maisProdutos">Ainda tem mais...</h1>
                <p>Sim!!, aqui foi uma pequena amostra do que vocẽ pode encontrar na nossa loja, então convidamos você a vir nos visitar, 
                    aqui na nossa loja você vai encontrar uma maior variedade de produtos e claro, sempre com a qualidade e preçio amigo
                    que a gente oferece.
                </p>
                <div className="showImages">
                    {imagesOther.map((image, index) => {
                        return (
                            <div key={index} className="boxImages">
                                <img src={image} alt={index}/>
                            </div>
                        );
                    })}
                </div>
            </ContainerOtherProducts>
        </ContainerProdutos>
    );
}