import React, { useState, useEffect } from 'react';
import { ContentBackToTop } from './styles';

import { MdKeyboardArrowUp } from 'react-icons/md';

export default function BackToTop(props) {
    const [opacity, setOpacity] = useState(0);
    const [bottom, setBottom] = useState('10px');

    function hanldeClick() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    useEffect(() => {
        if(props.scroll >= 500 ) {
            setOpacity(1);
            setBottom('50px');
        } else {
            setOpacity(0);
            setBottom('10px');
        }
        if(props.scroll >= 6800) {
            setBottom('220px');
        }
    }, [props.scroll])

    return (
        <ContentBackToTop onClick={hanldeClick} opacity={opacity} bottom={bottom}>
            <MdKeyboardArrowUp size={48}/>
        </ContentBackToTop>
    );
}